import { useUserStore } from '@st/user/stores/useUserStore'
import { delay } from '@st/utils'
import type { Notification, SocketNotification } from '../types'
import { NOTIFICATION_DURATION } from '../constants'

export const useNotificationsStore = defineStore('notifications', () => {
  const stFetch = useRawStFetch()
  const { isAuthenticated } = storeToRefs(useUserStore())

  const {
    data: activeNotificationsCounter,
    execute: fetchActiveNotificationsCounters,
    clear,
  } = useStFetch('/user-notification/find', {
    method: 'post',
    body: {
      params: {
        read: false,
      },
      pagination: {
        page: 0,
        perPage: 1,
        orderBy: [{ fieldName: 'createdAt', sortOrder: 'DESC' }],
      },
    },
    immediate: false,
    watch: false,
    transform: (response) => response?.paging?.total ?? 0,
    default: () => 0,
  })

  async function updateNotificationsCounters() {
    if (isAuthenticated.value) {
      await fetchActiveNotificationsCounters()
    } else clear()
  }

  const notificationsAlerts = ref<Notification[]>([])
  async function openNotificationAlert(item: SocketNotification) {
    const { userNotificationId: id, read, type, data: notificationData } = item
    const normalizedItem = {
      id,
      createdAt: '',
      read,
      type,
      data: notificationData,
      fileStorageId: null,
    } as Notification
    notificationsAlerts.value.unshift(normalizedItem)
    await nextTick()
    await delay(NOTIFICATION_DURATION)
    notificationsAlerts.value.pop()
  }
  function closeNotificationAlert(id: number) {
    notificationsAlerts.value = notificationsAlerts.value.filter(
      (item) => item.id !== id,
    )
  }

  async function readNotification(id: number) {
    const { error } = await stFetch('/user-notification/read', {
      method: 'post',
      body: {
        ids: [id],
      },
    })
    if (error) {
      console.error('Failed to read notification')
      return
    }
    closeNotificationAlert(id)
    await updateNotificationsCounters()
  }
  async function readAllNotifications() {
    const { error } = await stFetch('/user-notification/read/all', {
      method: 'post',
    })
    if (error) {
      console.error('Failed to read notification')
      return
    }
    await updateNotificationsCounters()
  }

  /**
   * запрашивает каунтеры при наличии юзера
   * сбрасывает в 0 при отсутствии
   */
  watch(
    () => isAuthenticated.value,
    () => {
      updateNotificationsCounters()
    },
    { immediate: true },
  )

  const io = useSocket()
  io.on('userNotification', async (payload: SocketNotification) => {
    await openNotificationAlert(payload)
    await updateNotificationsCounters()
  })

  return {
    activeNotificationsCounter,
    notificationsAlerts,
    readNotification,
    readAllNotifications,
    openNotificationAlert,
  }
})
