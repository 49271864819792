import { useWebPushStore } from '../stores/useWebPushStore'

export default defineNuxtPlugin({
  name: 'webPush',
  parallel: true,
  order: 3,
  async setup() {
    const { isPwa } = useApplicationType()
    const webPushStore = useWebPushStore()
    const { registerConnection } = webPushStore
    const { hasSubscription } = storeToRefs(webPushStore)
    if (hasSubscription.value && isPwa.value) {
      registerConnection()
    }
  },
})
