import { useUserStore } from '@st/user/stores/useUserStore'

export default defineNuxtRouteMiddleware(async (_, from) => {
  if (import.meta.server) return undefined

  const { isReady, user } = storeToRefs(useUserStore())
  await until(isReady).toBeTruthy()
  if (user.value?.kycLevel === -1) {
    return navigateTo({ query: { ...from.query, modal: 'createAccount' } })
  }
  return undefined
})
