export function useConnectTelegram() {
  const stFetch = useRawStFetch()
  const toast = useToast()
  const { locale, t } = useNuxtApp().$i18n

  const { handleTelegramAuth } = useTelegramAuthWidget()

  const { hasTelegramProvider, getAuthProvidersList } = useUserAuthProviders()
  const { isTelegramMiniApp, openTelegramBotFromMiniApp, telegram } =
    useTelegram()

  async function handleTelegramConnect() {
    if (!isTelegramMiniApp.value) {
      await handleTelegramAuth(
        { language: locale.value },
        '/user/auth/telegram',
      )
      return
    }

    // логика для tg-mini-app
    if (hasTelegramProvider.value) {
      openTelegramBotFromMiniApp()
      return
    }

    const { error } = await stFetch('/user/auth/telegram/mini-app', {
      method: 'post',
      body: {
        tgData: telegram?.initData ?? '',
      },
    })

    if (!error) {
      openTelegramBotFromMiniApp()
      getAuthProvidersList()
    } else {
      toast.open({
        type: 'negative',
        label: t('userVerificationModal.errors.somethingWentWrong'),
      })
    }
  }

  return {
    handleTelegramConnect,
  }
}
