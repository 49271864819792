<template>
  <StModal
    v-if="isModal"
    :model-value="isShownModal && !isInTransition"
    :close-button="!isLoadingContent && !currentModalConfig?.hideCloseButton"
    :close-on-click-outside="currentModalConfig?.closeOnClickOutside"
    @update:model-value="toggleModal($event)"
  >
    <Transition name="fade" mode="out-in">
      <component
        :is="currentModalComponent"
        v-bind="modalProps"
        @close="toggleModal(false)"
      />
    </Transition>
  </StModal>
  <StDrawer
    v-else
    :model-value="isShownModal && !isInTransition"
    v-bind="wrapperProps"
    :closable-with-swipe="currentModalConfig?.closableWithSwipe"
    @update:model-value="toggleModal($event)"
  >
    <ClientOnly>
      <Transition name="fade" mode="out-in">
        <Suspense>
          <component :is="currentModalComponent" v-bind="modalProps" />
          <template #fallback>
            <div class="drawer-content-loader">
              <StSpinner size="40" />
            </div>
          </template>
        </Suspense>
      </Transition>
    </ClientOnly>
  </StDrawer>
</template>

<script setup lang="ts">
import type { AsyncComponentLoader, Component } from 'vue'
import { delay, isNullish, omit } from '@st/utils'
import StModal from './StModal.vue'
import StDrawer from '../StDrawer/StDrawer.vue'

interface ModalConfig {
  component: AsyncComponentLoader | Component
  wrapper: 'modal' | 'drawer' | 'drawer-compact' | 'drawer-embeded-drag-line'
  closeOnClickOutside?: boolean
  hideCloseButton?: boolean
  closableWithSwipe?: boolean
}

const props = defineProps<{
  modals: Record<string, ModalConfig>
}>()

const route = useRoute()
const router = useRouter()

const currentModalName = computed({
  get: () => route.query.modal as string | undefined,
  set: (value) => {
    const updatedQuery = value
      ? { ...route.query, modal: value }
      : omit(route.query, ['modal'])

    router.replace({ query: updatedQuery })
  },
})

const modalProps = computed(() => omit(route.query, ['modal']))
const currentModalConfig = computed(() =>
  currentModalName.value ? props.modals[currentModalName.value] : null,
)
const currentModalComponent = computed(
  () => currentModalConfig.value?.component,
)

const isShownModal = ref(!!currentModalComponent.value)
const isInTransition = ref(false)
const isModal = computed(() => currentModalConfig.value?.wrapper === 'modal')

const isLoadingContent = ref(false)

function toggleModal(targetState: boolean) {
  isShownModal.value = isNullish(targetState)
    ? !isShownModal.value
    : targetState
}

/**
 * ЗАЧЕМ ТАК СЛОЖНА:
 * Попсы нужно обновлять ПОСЛЕ того как отработал Transition
 * Если сделать простой computed, пропсы будут обновляться ДО.
 * Это вызывает резкое изменение стилей модалки/drawer прямо в процессе ее исчезновения.
 */
function getWrapperProps() {
  const config = currentModalConfig.value
  if (!config) return {}

  const { wrapper } = config

  return {
    'full-screen': wrapper === 'drawer',
    'is-embeded-drag-line': wrapper === 'drawer-compact',
  }
}

const wrapperProps = ref({})

watch(
  currentModalComponent,
  async (current, previous) => {
    await nextTick()
    isShownModal.value = !!current
    if (current && previous) {
      isInTransition.value = true
      await delay(300)
      isInTransition.value = false
    }
    wrapperProps.value = getWrapperProps()
  },
  {
    immediate: true,
  },
)

watch(isShownModal, async (value) => {
  await nextTick()
  if (!value) currentModalName.value = undefined
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drawer-content-loader {
  pointer-events: none;

  position: absolute;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
