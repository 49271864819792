import { useUserStore } from '@st/user/stores/useUserStore'
import { useTagsStore } from '@st/user/stores/useTagsStore'
import { provideVerificationDeps } from '@st/verification/useDeps'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import type { IconName } from '@st/ui/components/StIcon/types'
import { provideQuestsDeps } from '@st/quests/useDeps'
import { useCouponStore } from '@st/coupon/stores/useCouponStore'
import { provideBookmakerDeps } from '@st/bookmaker/useDeps'
import { provideBonusesDeps } from '@st/bonuses/useDeps'
import { useCasinoStore } from '@st/casino/stores/useCasinoStore'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import { useConverterStore } from '@st/payments/stores/useConverterStore'
import { useConnectTelegram } from '../composables/useConnectTelegram'

export default defineNuxtPlugin({
  order: 1,
  name: 'diContainer',
  dependsOn: ['i18n:plugin', 'i18n-base-init'],
  setup: () => {
    const tagsStore = useTagsStore()
    const userStore = useUserStore()
    const { hasTag } = tagsStore
    const { isReady: isReadyTags } = storeToRefs(tagsStore)
    const {
      isAuthenticated,
      isReady: isReadyUser,
      user,
    } = storeToRefs(userStore)

    const currencyStore = useCurrenciesStore()
    const { getCurrencyByCode, getCurrencyById } = currencyStore
    const { appCurrency } = toRefs(currencyStore)
    const { convert } = useConverterStore()
    const { getCategoryNameById } = useCasinoStore()
    const { getAccountById } = useAccountsStore()

    provideVerificationDeps({
      hasSkipKycLevelCheckOnDepositTag: computed(() =>
        hasTag('skipKycLevelCheckOnDeposit'),
      ),
      isAuthenticated,
      isReadyUser,
      isReadyTags,
      userKycLevel: computed(() => user.value?.kycLevel ?? null),
    })

    const router = useRouter()
    const route = useRoute()

    const { handleTelegramConnect } = useConnectTelegram()

    const couponStore = useCouponStore()

    provideBookmakerDeps({
      hasOutcome: couponStore.hasOutcome,
    })

    provideQuestsDeps({
      connectTelegram: async () => {
        await handleTelegramConnect()
      },
      openLogin: () => {
        router.push({ query: { ...route.query, modal: 'login' } })
      },
      isAuthenticated,
      isReadyUser,
      getCurrencyIcon: (code: string) =>
        getCurrencyByCode(code)?.icon as IconName,
    })

    provideBonusesDeps({
      isAuthenticated,
      referralCode: computed(() => user.value?.referralCode),
      appCurrencyCode: computed(() => appCurrency.value.code),
      getCurrencyIcon: (code: string) =>
        getCurrencyByCode(code)?.icon as IconName,
      getCurrencyIconById: (id: number) =>
        getCurrencyById(id)?.icon as IconName,
      getCurrencyCodeById: (id: number) => getCurrencyById(id)?.code as string,
      getCategoryNameById,
      getAccountById,
      getReferralCurrency: (id: number) => {
        const currency = getCurrencyById(id)
        if (!currency) return undefined

        return {
          icon: currency.icon,
          code: currency.code,
        }
      },
      convert,
    })
  },
})
