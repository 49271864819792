import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  type Messaging,
} from 'firebase/messaging'

const FIREBASE_SW_PATH = '/firebase-messaging-sw.js'

export const useWebPushStore = defineStore('webPush', () => {
  const { vapidKey, ...firebaseConfig } = useRuntimeConfig().public.firebase
  const isEnableNotifications = ref(
    !!globalThis.Notification && Notification.permission === 'granted',
  )

  async function requestNotificationPermission() {
    const permission = await Notification.requestPermission()
    isEnableNotifications.value = permission === 'granted'
  }

  const swRegistration = ref<ServiceWorkerRegistration>()
  async function getServiceWorker() {
    if (!('serviceWorker' in navigator)) {
      console.error('navigator does not support serviceWorker')
      return
    }
    swRegistration.value = await navigator.serviceWorker.getRegistration()
    if (!swRegistration.value) {
      swRegistration.value = await navigator.serviceWorker.register(
        FIREBASE_SW_PATH,
        {
          type: 'module',
        },
      )
    }
  }

  const messaging = ref<Messaging>()
  function registerFirebaseApp() {
    const app = initializeApp(firebaseConfig)
    messaging.value = getMessaging(app)
  }

  const hasFirebaseInstance = computed(() => !!messaging.value)

  async function registerConnection() {
    await getServiceWorker()
    registerFirebaseApp()
  }

  const subscribeToken = useLocalStorage<string>('subscribeToken', '')
  async function subscribe() {
    if (!hasFirebaseInstance.value) {
      await registerConnection()
    }
    const token = await getToken(messaging.value!, {
      serviceWorkerRegistration: swRegistration.value,
      vapidKey,
    })

    const { error } = useStFetch('/web-push/subscribe', {
      method: 'post',
      body: {
        token,
      },
    })
    if (!error.value) {
      subscribeToken.value = token
    }
  }

  async function unsubscribe() {
    if (!hasFirebaseInstance.value) {
      await registerConnection()
    }
    const { error } = useStFetch('/web-push/unsubscribe', {
      method: 'post',
      body: {
        token: subscribeToken.value,
      },
    })
    if (!error.value) {
      subscribeToken.value = ''
    }
  }

  const hasSubscription = computed({
    get: () => !!subscribeToken.value,
    set: (value: boolean) => {
      if (value) {
        subscribe()
      } else {
        unsubscribe()
      }
    },
  })

  async function init() {
    await requestNotificationPermission()
    await registerConnection()
    await subscribe()
  }

  watch(
    () => messaging.value,
    (value) => {
      if (!value) {
        return
      }
      onMessage(value, (payload) => {
        if (!swRegistration.value || !subscribeToken.value) {
          return
        }
        const notificationTitle = payload.notification?.title
        const notificationOptions = {
          body: payload.notification?.body,
        }
        swRegistration.value.showNotification(
          notificationTitle || '',
          notificationOptions,
        )
      })
    },
  )

  return {
    requestNotificationPermission,
    isEnableNotifications,
    messaging,
    init,
    unsubscribe,
    hasSubscription,
    swRegistration,
    registerConnection,
    subscribeToken,
  }
})
