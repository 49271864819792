import type { IconName } from '@st/ui/components/StIcon/types'

const [useQuestsDeps, provideQuestsDeps] = createEntityDeps<{
  getCurrencyIcon: (currencyCode: string) => IconName
  connectTelegram: () => Promise<void>
  isAuthenticated: Ref<boolean>
  openLogin: () => Promise<void> | void
  isReadyUser: Ref<boolean>
}>()

export { useQuestsDeps, provideQuestsDeps }
