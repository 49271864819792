import type { Telegram } from '../types'

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var Telegram: Telegram
}

interface AuthData {
  language: string
  promocodeName?: string
  referralCode?: string
}

export function useTelegram() {
  const stFetch = useRawStFetch()
  const { isTelegramMiniApp } = useApplicationType()

  const telegram: Telegram['WebApp'] = globalThis?.Telegram?.WebApp

  const config = useRuntimeConfig()
  const botName = config.public.telegramAuthBotName
  const botLink = computed(() => `https://t.me/${botName}`)

  async function auth(data: AuthData) {
    if (!telegram?.initDataUnsafe?.user?.id)
      throw new Error('Telegram auth error')

    const { data: userData } = await stFetch('/auth/telegram/mini-app', {
      method: 'post',
      body: {
        language: data.language as any,
        promocodeName: data.promocodeName,
        referralCode: data.referralCode,
        tgData: telegram.initData,
      },
    })

    return userData
  }

  function openTelegramLink(link: string) {
    telegram.openTelegramLink(link)
  }

  function openTelegramBotFromMiniApp() {
    openTelegramLink(botLink.value)
  }

  return {
    isTelegramMiniApp,
    telegram,
    auth,
    openTelegramLink,
    openTelegramBotFromMiniApp,
    botLink,
  }
}
